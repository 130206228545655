import * as React from "react"
import defaultTileImage from '../assets/media/project-tile.jpeg';
import {graphql} from 'gatsby';
import {urlPaths} from "../routes";
import {Col, Row} from "react-bootstrap";
import RenderBlocks from "../components/renderBlocks";
import SEO from "../components/seo";

export const query = graphql`
    query ProjectPageQuery {
        allDatoCmsProject(sort: {fields: position, order: ASC}) {
            edges {
                node {
                    title
                    content
                    id
                    url
                    locked
                    position
                    images {
                        alt
                        url
                        title
                    }
                }
            }
        }
        datoCmsPage(url: {eq: "projects"}) {
            title
            content {
                ... on DatoCmsCallToAction {
                    id
                    model {
                        apiKey
                    }
                    contentWidth
                    content
                    addLeftBorder
                }
            }
        }
    }
`

const ProjectsPage = ({data}) => {
    const {
        datoCmsPage: {
            title,
            content
        },
        allDatoCmsProject: {
            edges
        },
    } = data;

    return (
        <>
            <SEO title={title} />

            <div className={'project-page'}>
                <div className={'container'}>
                    <h1>Projects showcase</h1>
                    <RenderBlocks fields={content}/>
                    <Row className={'pt-4'}>
                        {edges?.map((item, idx) => {
                            const {node: {title, locked, url, images}} = item;
                            const tileImage = images[0]?.url || defaultTileImage;
                            const projectUrl = locked ? '#' : urlPaths.project + url;

                            return (
                                <Col md={6} lg={6} xl={4} className={'mb-3'} key={idx}>
                                    <a href={projectUrl} className={locked ? 'disabled' : 'active'}>
                                        <div
                                            className={'project-summary'}
                                            style={{backgroundImage: `url(${tileImage})`}}
                                        >
                                            {locked && (
                                                <div className={'coming-soon h-100'}>
                                                    <div>Coming soon</div>
                                                </div>
                                            )}
                                            {!locked && <div className={'title'}>{title}</div>}
                                        </div>
                                    </a>
                                </Col>
                            )
                        })}
                    </Row>
                </div>

                <div className={'gray-bottom'}/>
            </div>
        </>
    )
}

export default ProjectsPage
